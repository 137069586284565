<template>
  <TheHeader></TheHeader>
  <router-view />
  <TheFooter></TheFooter>
</template>

<script>
import TheFooter from './components/HeaderFooter/TheFooter.vue';
import TheHeader from './components/HeaderFooter/TheHeader.vue';
export default {
  name: 'App',
  components: {
    TheHeader,
    TheFooter,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
}
</script>

<style scoped></style>
