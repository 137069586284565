<template>
  <div class="container">
    <Intersect v-motion-fade :delay="300" class="intersect"></Intersect>
    <div class="title-group" v-motion-slide-top :delay="300">
      <h1 class="main_title">CORVICI</h1>
      <p class="second_title">S.R.O</p>
    </div>
    <BlueHeart v-motion-slide-left :delay="300" class="blue-heart"></BlueHeart>
    <RedHeart v-motion-slide-right :delay="300" class="red-heart"></RedHeart>
    <div class="images pc">
      <div
        class="up_img"
        @mouseover="zoomIn(0)"
        @mouseleave="zoomOut(0)"
        v-motion-slide-top
        :delay="100"
      >
        <div class="zoom">
          <router-link :to="{ name: 'uctovnictvo' }">
            <img
              src="./../assets/uctovnictvo.png"
              alt=""
              :class="{ zoomed: isZoomed[0] }"
            />
          </router-link>
          <p :class="{ zoomed: isZoomed[0] }">učtovnícto | mzdy</p>
        </div>
      </div>
      <div
        class="down_img"
        @mouseover="zoomIn(1)"
        @mouseleave="zoomOut(1)"
        v-motion-slide-bottom
        :delay="100"
      >
        <div class="zoom">
          <a href="https://kamene-svietniky-lampy.sk/" target="_blank">
            <img
              src="./../assets/kamienky.png"
              alt=""
              :class="{ zoomed: isZoomed[1] }"
            />
          </a>
          <p :class="{ zoomed: isZoomed[1] }">kamene-svietniky-lampy.sk</p>
        </div>
      </div>
      <div
        class="up_img bg-blue"
        @mouseover="zoomIn(2)"
        @mouseleave="zoomOut(2)"
        v-motion-slide-top
        :delay="300"
      >
        <div class="zoom">
          <a href="https://www.luboskukla.sk/" target="_blank">
            <img
              src="./../assets/pocitac.png"
              alt=""
              :class="{ zoomed: isZoomed[2] }"
            />
          </a>
          <p :class="{ zoomed: isZoomed[2] }">web, logo, dizajn, eshop</p>
        </div>
      </div>
      <div
        class="down_img bg-green"
        @mouseover="zoomIn(3)"
        @mouseleave="zoomOut(3)"
        v-motion-slide-bottom
        :delay="300"
      >
        <div class="zoom">
          <a href="https://verchielkreativ.sk/" target="_blank">
            <img
              src="./../assets/stolar.png"
              alt=""
              :class="{ zoomed: isZoomed[3] }"
            />
          </a>
          <p :class="{ zoomed: isZoomed[3] }">verchielkreativ.sk</p>
        </div>
      </div>
      <div
        class="up_img"
        @mouseover="zoomIn(4)"
        @mouseleave="zoomOut(4)"
        v-motion-slide-top
        :delay="500"
      >
        <div class="zoom">
          <router-link :to="{ name: 'house' }">
            <img
              src="./../assets/dom.png"
              alt=""
              :class="{ zoomed: isZoomed[4] }"
            />
          </router-link>
          <p :class="{ zoomed: isZoomed[4] }">domov, interier, návrhy</p>
        </div>
      </div>
    </div>
  </div>

  <div class="images mobile">
    <div
      class="up_img"
      @mouseover="zoomIn(0)"
      @mouseleave="zoomOut(0)"
      v-motion-slide-top
      :delay="100"
    >
      <div class="zoom">
        <router-link :to="{ name: 'uctovnictvo' }">
          <img
            src="./../assets/uctovnictvo.png"
            alt=""
            :class="{ zoomed: isZoomed[0] }"
          />
        </router-link>
        <p :class="{ zoomed: isZoomed[0] }">učtovnícto | mzdy</p>
      </div>
    </div>
    <div
      class="down_img"
      @mouseover="zoomIn(1)"
      @mouseleave="zoomOut(1)"
      v-motion-slide-bottom
      :delay="100"
    >
      <div class="zoom">
        <a href="https://kamene-svietniky-lampy.sk/" target="_blank">
          <img
            src="./../assets/kamienky.png"
            alt=""
            :class="{ zoomed: isZoomed[1] }"
          />
        </a>
        <p :class="{ zoomed: isZoomed[1] }">kamene-svietniky-lampy.sk</p>
      </div>
    </div>
    <div
      class="up_img bg-blue"
      @mouseover="zoomIn(2)"
      @mouseleave="zoomOut(2)"
      v-motion-slide-top
      :delay="300"
    >
      <div class="zoom">
        <a href="https://www.luboskukla.sk/" target="_blank">
          <img
            src="./../assets/pocitac.png"
            alt=""
            :class="{ zoomed: isZoomed[2] }"
          />
        </a>
        <p :class="{ zoomed: isZoomed[2] }">web, logo, dizajn, eshop</p>
      </div>
    </div>
    <div
      class="down_img bg-green"
      @mouseover="zoomIn(3)"
      @mouseleave="zoomOut(3)"
      v-motion-slide-visible-once-bottom
      :delay="300"
    >
      <div class="zoom">
        <a href="https://verchielkreativ.sk/" target="_blank">
          <img
            src="./../assets/stolar.png"
            alt=""
            :class="{ zoomed: isZoomed[3] }"
          />
        </a>
        <p :class="{ zoomed: isZoomed[3] }">verchielkreativ.sk</p>
      </div>
    </div>
    <div
      class="up_img"
      @mouseover="zoomIn(4)"
      @mouseleave="zoomOut(4)"
      v-motion-slide-visible-once-top
      :delay="500"
    >
      <div class="zoom">
        <router-link :to="{ name: 'house' }">
          <img
            src="./../assets/dom.png"
            alt=""
            :class="{ zoomed: isZoomed[4] }"
          />
        </router-link>
        <p :class="{ zoomed: isZoomed[4] }">domov, interier, návrhy</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BlueHeart from "./../components/BlueHeart.vue";
import Intersect from "./../components/Intersect.vue";
import RedHeart from "./../components/RedHeart.vue";

export default {
  name: "HomeView",
  components: {
    BlueHeart,
    Intersect,
    RedHeart,
  },
  setup() {
    const isZoomed = ref([false, false, false, false, false]);

    function zoomIn(index) {
      isZoomed[index] = true;
    }

    function zoomOut(index) {
      isZoomed[index] = false;
    }

    return {
      isZoomed,
      zoomIn,
      zoomOut,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.container {
  background-image: url(./../assets/Siet.svg);
  background-repeat: no-repeat;
  background-size: 90%;
  background-position-y: 5em;
  background-position-x: 4em;
}

.title-group {
  text-align: center;
  font-family: "Amaranth", sans-serif !important;
}

.main_title {
  font-size: 13.125em;
  font-weight: 700;
  display: inline;
  margin-right: 10px;
}

.second_title {
  display: inline;
  font-size: 1.25em;
  font-weight: 700;
}

.images {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -4em;
  margin-bottom: 8em;
}

.mobile {
  display: none;
}

.zoom {
  transition-duration: 1s;
}

.images a {
  display: inline-block;
  width: 16em;
  height: 17em;
  filter: grayscale(100%);
  text-align: center;
}

.up_img a {
  background-color: #feb3b6;
}

.bg-blue a {
  background-color: #36b7c1;
}

.down_img a {
  background-color: #c3b1e1;
}

.bg-green a {
  background-color: #6599a3;
}

.up_img img {
  width: 13.5625em;
  height: 17em;
  transition: all 0.5s;
  transform-origin: center center;
  position: relative;
  object-fit: contain;
  transition-duration: 0.2s;
}

.down_img img {
  width: 13.5625em;
  height: 17em;
  transition: all 0.5s;
  transform-origin: center center;
  position: relative;
  filter: grayscale(100%);
  object-fit: contain;
  transition-duration: 0.2s;
}

.zoom:hover,
.up_img:hover,
.down_img:hover {
  transform: scale(1.5);
  z-index: 2;
}

.down_img img:hover,
.up_img img:hover {
  filter: grayscale(0%);
}

.down_img a:hover,
.up_img a:hover {
  filter: grayscale(0%);
}

.up_img img.zoomed {
  transform: scale(2);
}

.up_img p {
  text-decoration: none;
  text-align: center;
  color: black;
}

p.zoomed {
  font-size: 1.2em;
}

.down_img {
  margin-top: 10em;
}

.down_img p {
  text-decoration: none;
  text-align: center;
  color: black;
}

.red-heart {
  display: flex;
  justify-content: right;
  margin-bottom: 5em;
  margin-right: 15em;
}

.blue-heart {
  display: flex;
  justify-content: left;
  position: absolute;
  left: 15.3em;
  top: 19em;
}

.intersect {
  position: absolute;
  top: 0em;
  right: -2.5em;
}

@media screen and (max-width: 1300px) {
  .images {
    font-size: 0.8em;
  }

  .blue-heart {
    left: 8.3em;
  }

  .red-heart {
    margin-bottom: 4em;
    margin-right: 9em;
  }
}

@media screen and (max-width: 1085px) {
  .container {
    background-size: 100%;
    background-position-y: 5.5em;
    background-position-x: 2.5em;
  }

  .images {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }

  .mobile {
    display: none;
  }

  .up_img,
  .down_img {
    width: 33%;
  }

  .down_img {
    margin-top: 0em;
  }

  .blue-heart {
    left: 4.3em;
  }

  .red-heart {
    margin-bottom: 8em;
    margin-right: 3em;
  }
}

@media screen and (max-width: 1039px) {
  .blue-heart {
    top: 11em;
  }

  .red-heart {
    margin-right: 7em;
  }

  .title-group {
    font-size: 80%;
  }
}

@media screen and (max-width: 945px) {
  .container {
    background-image: none;
  }
}

@media screen and (max-width: 838px) {
  .title-group {
    margin-top: 2em;
  }

  .red-heart {
    margin-right: 3em;
    margin-top: -2em;
  }
}

@media screen and (max-width: 816px) {
  .up_img,
  .down_img {
    width: 40%;
  }
}

@media screen and (max-width: 796px) {
  .blue-heart {
    left: 1.3em;
  }

  .title-group {
    font-size: 70%;
  }
}

@media screen and (max-width: 716px) {
  .blue-heart {
    display: none;
  }

  .red-heart {
    display: none;
  }

  .images {
    margin-top: 4em;
  }
}

@media screen and (max-width: 625px) {
  .up_img,
  .down_img {
    width: 100%;
  }

  .images a,
  .images img {
    filter: grayscale(0%);
  }

  .pc {
    display: none;
  }

  .mobile {
    display: block;
  }
}

@media screen and (max-width: 576px) {
  .title-group {
    font-size: 60%;
  }
}

@media screen and (max-width: 500px) {
  .title-group {
    font-size: 50%;
  }
}

@media screen and (max-width: 416px) {
  .title-group {
    font-size: 40%;
  }
}

@media screen and (max-width: 343px) {
  .title-group {
    font-size: 35%;
  }
}
</style>
