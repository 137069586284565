import { createRouter, createWebHistory } from 'vue-router'
import HomeView from './../views/HomeView.vue'
import UctovnictvoView from './../views/UctovnictvoView.vue'
import HouseView from './../views/HouseView.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/uctovnictvo',
    name: 'uctovnictvo',
    component: UctovnictvoView,
  },
  {
    path: '/house',
    name: 'house',
    component: HouseView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
