<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="1205" height="771" viewBox="0 0 1205 771" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M635.622 1007.93L1204.07 698.803C1022.87 483.083 493.234 74.2885 -276.872 0.756618C-150.345 590.536 277.044 884.33 635.622 1007.93Z" fill="url(#paint0_linear_60_57)"/>
      <defs>
        <linearGradient id="paint0_linear_60_57" x1="-237.023" y1="39.1669" x2="452.73" y2="677.738" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FDEEDB"/>
          <stop offset="1" stop-color="#FDEEDB" stop-opacity="0"/>
        </linearGradient>
      </defs>
    </svg>
</template>
<script>
export default {
  name: 'YellowArc',
};
</script>
<style scoped>
svg {
  fill: linear-gradient(193deg, #FCEEDB 0%, #FDD8C5 25.32%, #FF4D8B 66.15%, #AA28BD 83.85%, #0044CD 100%);
}
</style>