<template>
    <nav>
        <div class="logo" v-motion-slide-top :delay="500">
            <router-link :to="{ name: 'home' }"> <img src="./../../assets/Logo.svg" alt="logo" class="logo"></router-link>
        </div>
        <div v-motion-slide-top :delay="500" class="mobile hamburger-dropdown">
            <div @click="toggleOpen" :class="{ opened: isActive }" class="hamburger-lines">
                <span class="line line1"></span>
                <span class="line line2"></span>
                <span class="line line3"></span>
            </div>
        </div>
        <div class="navigation pc">
            <div class="pc">
                <router-link v-motion-pop to="/" class="link-1">
                    <p>Domov</p>
                </router-link>
                <router-link v-motion-pop :delay="100" :to="{ name: 'uctovnictvo' }" class="link-2">
                    <p>Účtovníctvo</p>
                </router-link>
                <router-link v-motion-pop :delay="200" :to="{ name: 'house' }" class="link-3">
                    <p>Interiér</p>
                </router-link>
            </div>
        </div>
    </nav>

    <div class="mobile">
        <div :class="{ opened: isActive }" class="pc menu mobile-navigation">
            <ul>
                <li @click="toggleOpen">
                    <router-link to="/">Domov</router-link>
                </li>
                <li @click="toggleOpen">
                    <router-link :to="{ name: 'uctovnictvo' }">Účtovníctvo</router-link>
                </li>
                <li @click="toggleOpen">
                    <router-link :to="{ name: 'house' }">Interiér</router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isActive: false,
        };
    },

    methods: {
        toggleOpen: function () {
            this.isActive = !this.isActive;
            // some code to filter users
        },
    },
};
</script>

<style scoped>
nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5em;
    margin-top: 4em;
    text-decoration: none;
}

.navigation {
    justify-content: center;
    width: 100%;
    display: flex;
}

.pc {
    display: flex;
    align-items: center;
    width: 23.8125em;
    height: 2.6875em;
    margin-left: auto;
    margin-right: 3em;
    gap: 2.5em;
    z-index: 1;
}

.pc a {
    text-decoration: none;
    color: #2F2A28;
    border: 0.0625em solid #BDB7B2;
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(0.3125em);
}

.pc a:focus {
    border: 3px solid #BDB7B2;
    background: rgba(228, 228, 228, 0.40);
    backdrop-filter: blur(5px);
}

.link-1 {
    height: 2.6875em;
    display: flex;
    align-items: center;
    background-color: #FFFFFF66;
}

.link-2 {
    height: 2.6875em;
    display: flex;
    align-items: center;
    background-color: #FFFFFF66;
}

.link-3 {
    height: 2.6875em;
    display: flex;
    align-items: center;
    background-color: #FFFFFF66;
}

.link-1 p,
.link-2 p,
.link-3 p {
    font-weight: 800;
    font-size: 1.25em;
    line-height: 1.7075em;
    padding: 0.4375em;
}

.logo {
    width: 4.375em;
    height: 4.375em;
    margin-left: 3em;
}

.mobile-navigation {
    padding: 1.5em 3%;
    z-index: 100;
    width: 96%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
}

.mobile-navigation {
    width: 20%;
    display: flex;
    align-items: center;
}

nav .logo a img,
.mobile-navigation .logo a img {
    width: 90%;
}

.mobile-navigation {
    width: 80%;
}

nav ul,
.mobile-navigation ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    list-style: none;
    gap: 3.5%;
    font-size: 0.95em;
    padding-right: 4%;
    padding-left: 0;
}

nav ul li a,
.mobile-navigation ul li a {
    text-decoration: none;
    color: black;
    position: relative;
}

.mobile-navigation {
    width: 100%;
    margin-top: 6em;
}

.mobile-navigation ul {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 1em 10%;
}

.mobile-navigation ul li {
    line-height: 2em;
    font-size: 1.15em;
}

.mobile-navigation ul li:not(:first-child) {
    border-top: black 0.15em solid;
}

.mobile-navigation .language {
    width: 100%;
    gap: 0.5em;
    justify-content: center;
}

.router-link-active {
    font-weight: 600;
}

.mobile-navigation .router-link-active:after {
    content: "";
    position: absolute;
    width: 70%;
    display: block;
    height: 2px;
    background-color: grey;
    bottom: -5px;
    animation: active 1s cubic-bezier(0, 0.52, 0.58, 1);
}

@keyframes active {
    from {
        width: 0%;
    }

    to {
        width: 70%;
    }
}

@media screen and (min-width: 650px) {
    .mobile {
        display: none;
        position: relative;
    }
}

@media screen and (max-width: 650px) {
    nav {
        justify-content: space-between;
        flex-direction: row;
        padding: 1.5em 8%;
        width: 84%;
        margin-top: 0em;
    }

    nav .logo {
        width: 45%;
        justify-content: flex-start;
    }

    nav .hamburger-dropdown {
        justify-content: flex-end;
        width: 45%;
        display: flex;
        cursor: pointer;
    }

    nav .navigation {
        justify-content: center;
        width: 100%;
        display: flex;
    }

    .pc {
        display: none !important;
    }

    .mobile {
        display: flex;
        justify-content: flex-end;
        width: 104%;
    }

    .opened {
        display: flex !important;
    }

    .mobile-navigation ul {
        width: 100%;
        margin: 0.2em;
        padding-right: 0em;
        background-color: #c3c3c34D;
    }

    .mobile-navigation ul li {
        margin-left: 7%;
    }

    .mobile-navigation ul li:not(:first-child) {
        border-top: rgba(239, 239, 239, 0.01) 0.15em solid;
    }

    .pc {
        margin-right: 0em;
    }

    .pc a {
        border: none;
        background-color: transparent;
    }

    .mobile-navigation {
        position: relative;
        padding: 0em;
        margin-top: 0.5em;
    }

    nav .logo a img,
    .mobile-navigation .logo a img {
        width: 4.375em;
        margin-left: 0em;
    }

    .logo {
        margin-left: 0em;
    }

    /* Lines hamburger */
    .hamburger-lines {
        display: block;
        height: 26px;
        width: 32px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .hamburger-lines .line {
        display: block;
        height: 4px;
        width: 100%;
        border-radius: 10px;
        background: #c3c3c3;
        transition: transform 0.4s ease-in-out;
    }

    .hamburger-lines .line1 {
        transform-origin: 0% 0%;
        transition: transform 0.4s ease-in-out;
    }

    .hamburger-lines .line2 {
        transition: transform 0.2s ease-in-out;
    }

    .opened.hamburger-lines .line3 {
        transform-origin: 0% 100%;
        transition: transform 0.4s ease-in-out;
    }

    .opened.hamburger-lines .line1 {
        transform: rotate(45deg);
    }

    .opened.hamburger-lines .line2 {
        transform: scaleY(0);
    }

    .opened.hamburger-lines .line3 {
        transform: rotate(-45deg);
    }
}
</style>