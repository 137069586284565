<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/multi-word-component-names -->
// eslint-disable-next-line vue/multi-word-component-names
<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="261" height="804" viewBox="0 0 261 804" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M337.131 788.322L951.285 803.999C904.704 540.606 663.274 -46.9534 64.9681 -473C-110.977 72.0059 100.97 516.466 337.131 788.322Z" fill="url(#paint0_linear_41_17)"/>
    <defs>
    <linearGradient id="paint0_linear_41_17" x1="355.514" y1="-48.1989" x2="443.356" y2="713.34" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF4863"/>
    <stop offset="1" stop-color="#DE2BAB" stop-opacity="0"/>
    </linearGradient>
    </defs>
    </svg>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Intersect',
};
</script>

<style>
svg {
    z-index: -1;
}
</style>