<!-- eslint-disable vue/multi-word-component-names --><!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="container">
        <UpperArc class="second-yellow-arc" v-motion-slide-right :delay="200"> </UpperArc>
        <div class="title" v-motion-slide-top :delay="300">
            <h1 class="main-title">Účtovníctvo, Mzdy, Daňové poradenstvo</h1>
        </div>
        <div class="img-place" v-motion-fade :delay="600">
            <img src="./../assets/uctovnik.png" alt="">
            <BlueHeart class="blue-heart" v-motion-slide-right></BlueHeart>
        </div>
        <div class="works">
            <div class="content-works">
                <h2 v-motion-slide-bottom :delay="300">Zabezpečenie prác</h2>
                <ul v-motion-slide-bottom :delay="600">
                    <li>jednoduché účtovníctvo</li>
                    <li>podvojné účtovníctvo</li>
                    <li>evidencia majetku</li>
                </ul>
            </div>
        </div>
        <div class="works">
            <div class="content-works">
                <h2 v-motion-slide-bottom :delay="300">Daňové služby</h2>
                <ul v-motion-slide-bottom :delay="600">
                    <li>daňové priznanie typ A</li>
                    <li>daňové priznanie typ B</li>
                    <li>daňové priznanie PO</li>
                    <li>daň z motorových vozidiel</li>
                    <li>vrátenie DPH EU</li>
                    <li>daňové priznanie k dani z pridanej hodnoty, kontrolný výkaz</li>
                </ul>
            </div>
        </div>
        <RedHeart class="heart" v-motion-slide-visible-once-left :delay="300"> </RedHeart>
        <div class="works">
            <div class="content-works">
                <h2 v-motion-slide-bottom :delay="300">Personálne služby</h2>
                <ul v-motion-slide-bottom :delay="600">
                    <li>personálna evidencia</li>
                    <li>mzdové služby</li>
                </ul>
            </div>
        </div>
        <div class="for-more-info">
            <div class="content-info" v-motion-slide-bottom :delay="300">
                <p>Pre viac informácií:</p>
                <a href="mailto:petronela.dendisova@gmail.com"><button class="btn">
                        <p>kontaktujte Nás</p>
                    </button></a>
            </div>
        </div>
        <YellowArc class="yellow-arc" v-motion-slide-left :delay="300"></YellowArc>
    </div>
</template>
   
<script>
import RedHeart from './../components/RedHeart.vue'
import YellowArc from './../components/YellowArc.vue';
import UpperArc from './../components/UpperArc.vue';
import BlueHeart from './../components/BlueHeart.vue';

export default {
    name: 'UctovnictvoView',
    components: {
        RedHeart,
        YellowArc,
        UpperArc,
        BlueHeart,
    },
    mounted() {
        window.scrollTo(0, 0);
    }
}
</script>
<style scoped>
.container {
    background-image: url(./../assets/Siet-uctovnictvo.svg);
    background-repeat: no-repeat;
}

.title {
    display: flex;
    align-items: center;
    width: 100vw;
    justify-content: center;
}

.main-title {
    width: 13em;
    color: #2F2A28;
    text-align: center;
    font-family: Amaranth;
    font-size: 5em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.img-place {
    display: flex;
    justify-content: center;
}

.img-place img {
    width: 72.3125em;
    flex-shrink: 0;
    background: lightgray 50% / cover no-repeat;
    height: auto;
}

.works {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 1.25em;
    width: 100%;
    justify-content: center;
}

.content-works {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
    width: 50%;
}

.content-works h2 {
    color: #2F2A28;
    font-size: 2em;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.content-works ul {
    color: #2F2A28;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 1em;
    margin-top: -1em;
    width: 100%;
}

.content-works li {
    padding: 0.2em;
}

.heart {
    display: flex;
    justify-content: center;
    width: 33%;
}

.for-more-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 10em;
    margin-bottom: 20em;
}

.content-info p {
    width: 11.25em;
    color: #2F2A28;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.content-info a {
    text-decoration: none;
    color: #000;
}

.btn {
    width: 13.375em;
    height: 2.9375em;
    display: flex;
    padding: 0.625em 1.25em;
    justify-content: center;
    align-items: center;
    gap: 0.625em;
    border: 1px solid #BDB7B2;
    transition-duration: 2s;
    background: #FFF;
    cursor: pointer;
    font-size: 1em;
}

.btn:focus,
.btn:hover {
    background: #fff;
    animation: pulse-169a02ec 1s;
    box-shadow: inset 0 0 0 2em transparent, 0 0 0 1em transparent;
    color: black;
    transform: scale(1.1);
}

.second-yellow-arc {
    position: absolute;
    top: 0em;
    right: 0em;
    z-index: -1;
}

.yellow-arc {
    position: absolute;
    top: 116em;
    left: -1em;
}


@media screen and (max-width: 1600px) {
    .img-place img {
        width: 54em;
    }

    .container {
        background-size: 131%;
        background-position-x: -17em;
        background-position-y: 2em;
    }
}

@media screen and (max-width: 945px) {
    .container {
        background-image: none;
    }

    .img-place img {
        width: 44em;
    }
}

@media screen and (max-width: 820px) {
    .title h1 {
        width: 9em;
    }
}

@media screen and (max-width: 790px) {
    .img-place {
        font-size: 80%;
    }

    .blue-heart {
        display: none;
    }
}

@media screen and (max-width: 605px) {
    .img-place {
        font-size: 70%;
    }
}

@media screen and (max-width: 580px) {
    .title {
        font-size: 80%;
    }
}

@media screen and (max-width: 555px) {
    .works {
        font-size: 85%;
    }

    .for-more-info {
        margin-top: 5em;
        margin-bottom: 8em;
    }
}

@media screen and (max-width: 553px) {
    .yellow-arc {
        top: 94em;
    }
}


@media screen and (max-width: 525px) {
    .img-place {
        font-size: 60%;
    }
}

@media screen and (max-width: 470px) {
    .works {
        font-size: 75%;
    }

    .yellow-arc {
        top: 85em;
    }
}

@media screen and (max-width: 468px) {
    .title {
        font-size: 70%;
    }

    .img-place img {
        width: 35em;
    }

    .heart {
        font-size: 80%;
    }
}

@media screen and (max-width: 425px) {
    .title {
        font-size: 60%;
    }
}

@media screen and (max-width: 414px) {
    .works {
        font-size: 65%;
    }

    .yellow-arc {
        top: 77em;
    }

    .heart {
        font-size: 65%;
    }
}

@media screen and (max-width: 373px) {
    .title h1 {
        font-size: 4.5em;
    }

    .img-place img {
        width: 30em;
    }
}

@media screen and (max-width: 355px) {
    .works {
        font-size: 55%;
    }
}

@media screen and (max-width: 351px) {
    .yellow-arc {
        top: 76em;
    }
}

@media screen and (max-width: 328px) {
    .title h1 {
        font-size: 4em;
    }

    .img-place img {
        width: 27em;
    }
}
</style>