<template>
    <div class="container">
        <UpperArc class="second-yellow-arc" v-motion-slide-right :delay="300"></UpperArc>
        <div class="title" v-motion-slide-top :delay="300">
            <h2 class="main-title">Dizajn interiéru</h2>
        </div>
        <div class="content-right">
            <img class="content-img" v-motion-slide-left :delay="300" src="./../assets/dizajn.png" alt="">
            <div class="content-right-text" v-motion-pop :delay="400">
                <h2>Nákres interiéru</h2>
                <p>Nákres interiéru je dôležitý pre plánovanie útulného a funkčného domova. Pomáha vizualizovať dispozíciu
                    miestnosti a umiestnenie prvkov.
                    Je kľúčovým nástrojom pred realizáciou plánu.
                </p>
            </div>
        </div>
        <BlueHeart class="blue-heart" v-motion-slide-top :delay="400"></BlueHeart>
        <div class="content-left">
            <div class="content-left-text" v-motion-pop :delay="400">
                <h2>Osobný návrh (Návšteva)</h2>
                <div class="content-left-group">
                    <p>Základom pre vytvorenie jedinečného a funkcionalného prostredia, ktoré bude odrážať vašu osobnosť a
                        štýl.
                        Sme presvedčení, že každý projekt je jedinečný a zaslúži si osobný prístup.
                        Preto sa budeme snažiť porozumieť vašim predstavám, potrebám a očakávaniam,
                        aby sme mohli vypracovať návrh, ktorý bude úplne na mieru pre Vás.</p>
                </div>
            </div>
            <img src="./../assets/stavebne-upravy.png" alt="" v-motion-slide-right :delay="300">
        </div>
        <div class="content-right mg-bt">
            <img class="content-right-img" v-motion-slide-left :delay="300" src="./../assets/zabespecenie-prac.png" alt="">
            <div class="content-right-text" v-motion-pop :delay="400">
                <h2>Poradenstvo</h2>
                <p>Radi vám pomôžeme vytvoriť dokonalý interiér pre váš domov,
                    kanceláriu alebo akýkoľvek iný priestor. Nech už plánujete zrenovovať
                    celý interiér alebo jednoducho potrebujete inšpiráciu a rady na úpravu
                    existujúceho priestoru, sme tu,
                    aby sme vás podporili na vašej ceste k vytvoreniu krásneho, funkčného a pohodlného interiéru. </p>
            </div>
        </div>
        <RedHeart class="red-heart" v-motion-slide-visible-once-right :delay="300"></RedHeart>
        <YellowArc class="yellow-arc" v-motion-slide-visible--once-left :delay="300"></YellowArc>
    </div>
</template>

<script>
import YellowArc from './../components/YellowArc.vue';
import RedHeart from './../components/RedHeart.vue';
import BlueHeart from '../components/BlueHeart.vue';
import UpperArc from '../components/UpperArc.vue';
export default {
    name: 'HouseView',
    components: {
        YellowArc,
        RedHeart,
        BlueHeart,
        UpperArc,
    },
    mounted() {
        window.scrollTo(0, 0);
    },
}
</script>

<style scoped>
.container {
    background-image: url(./../assets/Siet-interier.svg);
    background-repeat: no-repeat;
}

.title {
    display: flex;
    align-items: center;
    width: 100vw;
    justify-content: center;
    text-align: center;
}

.main-title {
    color: #2F2A28;
    font-family: Amaranth;
    font-size: 5em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.content-right {
    display: flex;
    margin-left: 10em;
}

.content-img {
    width: 16.3125em;
    height: 24.4375em;
    flex-shrink: 0;
}

.content-right-text {
    margin-left: 4em;
    margin-top: 2em;
    color: #2F2A28;
    font-style: normal;
    line-height: normal;
}

.content-right-text h2 {
    font-size: 2em;
    font-weight: 800;
    margin-bottom: 1em;
}

.content-right-text p {
    width: 33em;
    font-size: 1.25em;
    font-weight: 400;
    margin-bottom: 6em;
}

.content-right-img {
    width: 16.3125em;
    height: 17.0625em;
    flex-shrink: 0;
}

.btn-works {
    display: inline-flex;
    width: 14.8em;
    height: 2.69em;
    padding: 0.625em 1.25em;
    justify-content: center;
    align-items: center;
    gap: 0.625em;
    border: 1px solid #BDB7B2;
    background: #FFF;
    color: #000;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.content-left {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3em;
}

.content-left-text {
    display: inline-flex;
    flex-direction: column;
    margin-left: 18%;
    align-items: center;
    margin-right: 4%;
}

.content-left-text h2 {
    margin-bottom: 1em;
    color: #2F2A28;
    text-align: right;
    font-size: 2em;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.content-left-group {
    width: 29.3125em;
    margin-left: 33%;
    color: #2F2A28;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.content-left-group p {
    width: 26em;
}

.content-left img {
    margin-left: 6%;
    width: 16.3125em;
    height: 24.4375em;
    flex-shrink: 0;
}

.red-heart {
    display: flex;
    justify-content: end;
    margin-bottom: 1em;
    width: 87%;
    margin-bottom: 8em;
}

.yellow-arc {
    position: absolute;
    top: 92em;
    left: -1em;
    height: 40em;
}

.blue-heart {
    position: absolute;
    top: 48em;
    left: 11em;
}

.second-yellow-arc {
    position: absolute;
    top: 0em;
    right: 0em;
    z-index: -1;
}


@media screen and (max-width: 1600px) {
    .blue-heart {
        bottom: 7em;
    }

    .container {
        background-size: 150%;
    }
}

@media screen and (max-width: 1200px) {
    .content-left-text {
        margin-left: 0em;
    }

    .content-left {
        margin-top: 2em;
    }
}


@media screen and (max-width: 1152px) {
    .content-right {
        font-size: 90%;
    }

    .content-left {
        font-size: 90%;
    }

    .blue-heart {
        top: 46em;
    }
}

@media screen and (max-width: 1040px) {
    .content-right-text p {
        width: 25em;
    }
}

@media screen and (max-width: 945px) {
    .container {
        background-image: none;
    }
}

@media screen and (max-width: 925px) {
    .content-right {
        font-size: 80%;
    }

    .content-left {
        font-size: 80%;
    }
}

@media screen and (max-width: 922px) {
    .yellow-arc {
        top: 84em;
    }
}

@media screen and (max-width: 915px) {
    .blue-heart {
        top: 43em;
    }
}

@media screen and (max-width: 890px) {
    .content-right {
        margin-left: 0em;
        justify-content: center;
    }

}

@media screen and (max-width: 770px) {
    .content-left {
        font-size: 70%;
    }

    .content-right {
        font-size: 70%;
    }

    .blue-heart {
        top: 41em;
    }
}

@media screen and (max-width: 768px) {
    .yellow-arc {
        top: 76em;
    }
}

@media screen and (max-width: 670px) {
    .title {
        width: 100%;
    }

    .content-left {
        flex-direction: column;
        align-items: center;
        font-size: 90%;
    }

    .mg-bt {
        margin-bottom: 4em;
    }

    .content-left img {
        margin-left: 0em;
    }

    .content-right {
        flex-direction: column;
        align-items: center;
        font-size: 90%;
    }

    .blue-heart {
        display: none;
    }

    .red-heart {
        display: none;
    }

    .yellow-arc {
        top: 143em;
    }
}

@media screen and (max-width: 655px) {
    .yellow-arc {
        top: 151em;
    }
}

@media screen and (max-width: 556px) {
    .content-left-group {
        width: unset;
        margin-left: 0em;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content-right-text {
        margin-left: 0em;
    }

    .content-right-text p {
        width: 20em;
    }

    .content-left-group p {
        width: 20em;
    }

    .yellow-arc {
        top: 160em;
    }
}

@media screen and (max-width: 416px) {
    .main-title {
        font-size: 4em;
    }

    .content-left {
        font-size: 80%;
        margin-right: 0em;
    }

    .content-right {
        font-size: 80%;
    }

    .content-left-text {
        margin-right: 0em;
    }

    .yellow-arc {
        top: 142em;
    }
}

@media screen and (max-width: 350px) {
    .content-left {
        font-size: 70%;
    }

    .content-right {
        font-size: 70%;
    }

    .yellow-arc {
        top: 128em;
    }
}
</style>
