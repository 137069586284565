<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="948" height="1466" viewBox="0 0 948 1466" fill="none">
    <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd"
      d="M1359 1431.05L0 1465.74C103.076 882.903 637.31 -417.246 1961.24 -1360C2350.57 -154.013 1881.57 829.487 1359 1431.05Z"
      fill="url(#paint0_linear_60_17)" />
    <defs>
      <linearGradient id="paint0_linear_60_17" x1="1318.32" y1="-420.002" x2="830.159" y2="1223.43"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#FCEEDB" />
        <stop offset="1" stop-color="#FCEEDB" stop-opacity="0.19" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'UpperArc'
}
</script>
<style  scoped></style>