<template>
  <div class="footer">
    <div class="adress" v-motion-slide-visible-once-left :delay="300">
      <h2>KONTAKT</h2>
      <div class="data">
        <p>ADRESA: SNP 1198/32-2, 026 01 DOLNÝ KUBÍN</p>
        <a href="tel:0950212660">
          <p>TEL. Č.: 0950 212 660</p>
        </a>
        <a href="mailto:petronela.dendisova@gmail.com">
          <p>MAIL: PETRONELA.DENDISOVA@GMAIL.COM</p>
        </a>
      </div>
      <div class="web_devoloper">
        <p>Všetky práva vyhradené © 2023</p>
        <p class="name_web">
          Web by
          <a href="https://www.luboskukla.sk" target="_blank"
            ><span>Ľuboš Kukla</span></a
          >
        </p>
      </div>
    </div>
    <div class="map" v-motion-fade-visible :delay="400">
      <div style="width: 100%">
        <iframe
          width="730"
          height="533"
          style="border: 0; filter: grayscale(100%)"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Na%20Sihoti%201168/10+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        >
        </iframe>
      </div>
    </div>
    <Arc
      v-if="$route.name == 'home'"
      v-motion-slide-visible-once-left
      :delay="300"
      class="arc"
    ></Arc>
  </div>
</template>
<script>
import Arc from "./../Arc.vue";
export default {
  name: "TheFooter",
  components: {
    Arc,
  },
};
</script>

<style scoped>
.footer {
  display: flex;
  background: rgba(239, 239, 239, 0.7);
  margin-left: -0.5em;
  width: 100vw;
  align-items: center;
  position: relative;
}

.footer a {
  text-decoration: none;
  color: #6d6d6d;
}

.adress {
  width: 54.5625em;
  flex-shrink: 0;
  z-index: 1;
  margin-left: 7em;
}

h2 {
  color: rgba(109, 109, 109, 0.5);
  font-size: 7.375em;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 0em;
  margin-top: 0em;
}

.data {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5625rem;
  margin-top: -1em;
  line-height: 0em;
  color: #6d6d6d;
}

.web_devoloper {
  display: flex;
  margin-top: 8em;
  justify-content: space-between;
  align-items: flex-start;
  color: #9e9e9e;
  font-size: 1em;
}

.name_web {
  width: 10.5em;
  flex-shrink: 0;
  margin-right: 3em;
}

.name_web p {
  color: #9e9e9e;
  font-size: 1em;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.name_web span {
  color: #34add6;
  font-size: 1em;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.map {
  flex-shrink: 0;
  background: lightgray 50% / cover no-repeat;
  width: 50%;
}

iframe {
  width: 100%;
}

.arc {
  position: absolute;
  bottom: 0em;
  left: -1em;
  z-index: -1;
}

@media screen and (max-width: 1600px) {
  .footer {
    font-size: 0.8em;
  }

  .adress {
    margin-left: 4em;
  }
}

@media screen and (max-width: 1240px) {
  .footer {
    display: flex;
    flex-direction: column;
    font-size: 1em;
    align-items: center;
  }

  .adress {
    margin-left: 7em;
  }

  .map {
    width: 90%;
  }

  .arc {
    bottom: 13em;
  }
}

@media screen and (max-width: 890px) {
  .footer {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 673px) {
  .adress {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0em;
  }

  .data {
    margin-top: 1em;
    gap: 0em;
    align-items: center;
  }

  .web_devoloper {
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-top: 3em;
  }

  .name_web {
    margin-right: 0em;
  }
}

@media screen and (max-width: 629px) {
  .arc {
    bottom: 7em;
  }
}

@media screen and (max-width: 542px) {
  h2 {
    font-size: 6em;
  }
}

@media screen and (max-width: 417px) {
  h2 {
    font-size: 4.5em;
  }
}
</style>
