<template>
    <div>
        <i class="fas fa-heart" :style="{ fontSize: '3em' }"></i>
    </div>
</template>

<script>
export default {
    name: 'BlueHeart',
};
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');
i {
    color: #01A1D8;
    z-index: -1;
}
</style>
