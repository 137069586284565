<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="857" height="926" viewBox="0 0 857 926" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M242.131 1261.32L856.285 1277C809.704 1013.61 568.274 426.047 -30.0319 0C-205.977 545.006 5.97006 989.466 242.131 1261.32Z" fill="url(#paint0_linear_41_20)"/>
    <defs>
    <linearGradient id="paint0_linear_41_20" x1="-15.5" y1="50.5" x2="248.5" y2="903" gradientUnits="userSpaceOnUse">
    <stop stop-color="#01A1D8"/>
    <stop offset="1" stop-color="#01A1D8" stop-opacity="0"/>
    </linearGradient>
    </defs>
    </svg>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Arc',
};
</script>

<style>
svg {
    fill: linear-gradient(157deg, #01A1D8 0%, rgba(1, 161, 216, 0.00) 100%);
    z-index: -1;
}
</style>
